import React from 'react';
import axios from 'axios';
class TempLookup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            stuff: 'goes here'
        }
        this.getUnfulfilledOrders = this.getUnfulfilledOrders.bind(this);
        this.getPartiallyFulfilledOrders = this.getPartiallyFulfilledOrders.bind(this);
        this.putOrderFulfillment = this.putOrderFulfillment.bind(this);
        this.putAllOrdersFulfillment = this.putAllOrdersFulfillment.bind(this);
    }

    getUnfulfilledOrders() {
        console.log('In getUnfulfilledOrders');
        axios.get("/shopify-unfulfilled-orders").then((res) => {
            console.log('res from getUnfulfilledOrder req: ', res);
        });
    }

    getPartiallyFulfilledOrders = () => {
        console.log('In getPartiallyFulfilledOrders');
        axios.get("/shopify-partially-fulfilled-orders").then((res) => {
            console.log('res from putOrderFulfillment req: ', res);
        });
        // var shopifyURL = 'https://siren-marine-migration.myshopify.com/admin/orders.json?fulfillment_status=partial';
        //GET req to Shopify for all partially unfulfilled orders 
        //THEN iterate through res and console.log each order && console.log order id and fulfillment_status AND line items
    }

    putOrderFulfillment = (orderID) => {
        console.log('In putOrderFulfillment. orderID provided: ', orderID);
        axios.put("/shopify-fulfill-order").then((res) => {
            console.log('res from putOrderFulfillment req: ', res);
        });
        // var shopifyURL = 'https://siren-marine-migration.myshopify.com/admin/orders.json?order_id=' + orderID +'&api_version=2021-10';
        // console.log('Shopify URL: ' + shopifyURL); //this Shopify URL format is not confirmed accurate
        //PUT req to Shopify w/ given orderID
        //THEN console.log return res & manually check that order was updated in Shopify
    }

    putAllOrdersFulfillment = () => {
        console.log('In putAllOrdersFulfillment');
        //Call getUnfulfilledOrders()
        //Iterate through res, check each line_item for presence of non-subscription products 
        //Call putOrderFulfillment(orderID) for each sub-only order
        
        //Call getPartiallyFulfilledOrders()
        //Attempt to call putOrderFulfillment(orderID) for each sub-only order
            //If this doesn't work, will need to try to update line items individually
    }

    render(){
        return(
            <div className='temp-lookup-container'>
                <div className='shopify-api-calls'>
                    <div className='dealer-form-field'>
                        <p className='form-field-descrip'>Click Below To Run getUnfulfilledOrders func</p>
                        <button className='form-submit-btn' onClick={()=>{this.getUnfulfilledOrders()}}>Submit</button>
                    </div>
                    <div className='dealer-form-field'>
                        <p className='form-field-descrip'>Click Below To Run getPartiallyFulfilledOrders func</p>
                        <button className='form-submit-btn' onClick={()=>{this.getPartiallyFulfilledOrders()}}>Submit</button>
                    </div>
                    <div className='dealer-form-field'>
                        <label className='form-field-label' htmlFor="shopify-orderid">Enter A Valid Order ID:<span className='req-field'>*</span></label>
                        <p className='form-field-descrip'>Enter an Order ID value then click button to run putOrderFulfillment</p>
                        <input className='form-field-input' type="number" id="shopify-orderid" name="shopify-orderid"></input>
                        <button className='form-submit-btn' onClick={()=>{this.putOrderFulfillment(document.getElementById("shopify-orderid").value)}}>Submit</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TempLookup;