import "./Header.css";

// import Nav from "react-bootstrap/Nav";

function Header() {
  return (
    <div id="header">
      <div id="logo">
        <img
          src={process.env.PUBLIC_URL + "/siren-logo.png"}
          id="appLogo"
          alt="logo"
          height="40"
        />
        <p id="logoText">Siren Apps</p>
      </div>
    </div>
  );
}

export default Header;
