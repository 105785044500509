import Header from "../Header/Header";
import Home from "../Home/Home.js";
function App() {
  return (
    <div className="App">
      <div id="headerContainer">
        <Header />
      </div>
      <div id="homeContainer">
        <Home />
      </div>
    </div>
  );
}

export default App;
