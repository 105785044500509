import "./home.css";
import Table from "react-bootstrap/Table";
import React from "react";
// import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import axios from "axios";
import JSONPretty from "react-json-pretty";

import TempLookup from './TempLookup.jsx';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.nextScheduledRun = "";
    this.state.lastRunStart = "";
    this.state.lastRunEnd = "";
    this.state.customersCreated = 0;
    this.state.customersUpdated = 0;
    this.state.customersChecked = 0;
    this.state.customersUpdatedError = 0;
    this.state.customersCreatedError = 0;
    this.state.missingProperties = {};
    this.state.lookupKey = "";
    this.state.userStateEmail = "";
    this.state.userState = "";
    this.getKeys = function (json) {
      console.log("json");
      console.log(json);
      return Object.keys(json[0]);
    };

    this.getHeader = function (json) {
      var keys = this.getKeys(json);
      return keys.map((key, index) => {
        return <th key={key}>{key.toUpperCase()}</th>;
      });
    };
    this.RenderRow = (props) => {
      return props.keys.map((key, index) => {
        return <td key={props.data[key]}>{props.data[key]}</td>;
      });
    };

    this.getRowsData = function (json) {
      var items = json;
      var keys = this.getKeys(json);
      return items.map((row, index) => {
        return (
          <tr key={index}>
            <RenderRow key={index} data={row} keys={keys} />
          </tr>
        );
      });
    };
    this.handleUserStateButton = (e) => {
      e.preventDefault();
      console.log(this.state.userStateEmail);
      //wrap the axios call in an 'if' statement verifying that lookup key matches env's lookup key
        //will also need to add the lookup key to env
        //will also need to add the env value to DigitalOcean
      axios
        .get("/user-state", { params: { email: this.state.userStateEmail } })
        .then((res) => {
          console.log(res);
          console.log(res.data.results.data);
          this.setState({
            userState: JSON.stringify(res.data.results.data, undefined, true),
          });
        });
    };
    this.handleChange = (e) => {
      this.setState({ userStateEmail: e.target.value });
    };
    this.handleLookupChange = (e) => {
      this.setState({lookupKey: e.target.value});
    };
  }

  componentDidMount() {
    axios.get("/jobs/hubspot-sync").then((res) => {
      console.log("response");
      console.log(res);
      let data = res.data.results.data;
      let state = {};
      let nextRun = new Date(data.next);
      nextRun.setHours(nextRun.getHours() - 4);

      let lastRunStart = new Date(data.datetimeStarted);
      let lastRunEnd = (data.datetimeEnded =
        /\d/.test(data.datetimeEnded) === false
          ? data.datetimeEnded
          : new Date(data.datetimeEnded));
      state.nextScheduledRun = nextRun.toLocaleString("en-US");
      state.lastRunStart = lastRunStart.toLocaleString("en-US");
      state.lastRunEnd =
        /\d/.test(lastRunEnd) === false
          ? lastRunEnd
          : lastRunEnd.toLocaleString("en-US");
      state.customersChecked = data.customersChecked;
      state.customersUpdated = data.customersUpdated;
      state.customersChecked = data.customersChecked;
      state.customersUpdatedError = data.customersUpdatedError;
      state.customersCreatedError = data.customersCreatedError;

      this.setState(state);
    });
    axios.get("/job-audits/hubspot-sync").then((res) => {
      this.setState({ missingProperties: res.data });
      console.log(this.state);
    });
  }

  render() {
    return (
      <div id="home">
        <div id="hubSyncSummary">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Hubspot Data User Sync</Accordion.Header>
              <Accordion.Body>
                <div className="summary-container">
                  <p className="center">
                    <span className="underline">Summary</span>
                  </p>
                  <div id="summary">
                    <p>
                      <span className="bold">Next Scheduled Run Time: </span>
                      {this.state.nextScheduledRun}
                    </p>
                    <p>
                      <span className="bold">Last run started: </span>
                      {this.state.lastRunStart}
                    </p>
                    <p>
                      <span className="bold">Last run ended: </span>
                      {this.state.lastRunEnd}
                    </p>
                    <p>
                      <span className="bold">Customers Checked: </span>
                      {this.state.customersChecked}
                    </p>
                    <p>
                      <span className="bold">Customers Updated: </span>
                      {this.state.customersUpdated}
                    </p>
                    <p>
                      <span className="bold">Customers Created: </span>
                      {this.state.customersCreated}
                    </p>
                    <p>
                      <span className="bold">
                        Customers with Error on Updating:
                      </span>
                      {this.state.customersUpdatedError}
                    </p>
                    <p>
                      <span className="bold">
                        Customers with Error on Creating:
                      </span>
                      {this.state.customersCreatedError}
                    </p>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Values for properties that don't exist in Hubspot.
              </Accordion.Header>
              <Accordion.Body>
                <div id="hubTables">
                  <Container>
                    <Row>
                      {Object.keys(this.state.missingProperties).map((prop) => (
                        <Col>
                          <div className="hub-table" key={prop}>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>
                                    {prop}:
                                    {this.state.missingProperties[prop].length}{" "}
                                    results
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.getRowsData(
                                  this.state.missingProperties[prop]
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="2">
              <Accordion.Header>
                Enter the email of the user who's information you are looking
                for.
              </Accordion.Header>
              <Accordion.Body>
                <InputGroup>
                <FormControl
                    onChange={this.handleLookupChange}
                    aceholder="LookupKey"
                    aria-label="LookupKey"
                    aria-describedby="lookupkey"
                    id="lookupkey"
                  />
                  <FormControl
                    onChange={this.handleChange}
                    aceholder="Email"
                    aria-label="Email"
                    aria-describedby="email"
                    id="email"
                  />
                  <Button
                    onClick={this.handleUserStateButton}
                    variant="outline-secondary"
                    id="userStateButton"
                  >
                    Button
                  </Button>
                </InputGroup>
                <div className="response">
                  <JSONPretty
                    id="json-pretty"
                    data={this.state.userState}
                  ></JSONPretty>
                </div>
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
          <TempLookup/>
        </div>
      </div>
    );
  }
}
const RenderRow = (props) => {
  return props.keys.map((key, index) => {
    return <td key={props.data[key]}>{props.data[key]}</td>;
  });
};

export default Home;
